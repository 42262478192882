<template>
  <b-container>
    <b-row class="justify-content-center"
           :class="[rowClass]">
      <b-col :cols="xs" :sm="sm" :md="md" :lg="lg" :xl="xl"
             :class="[colClass]">
        <slot></slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'center-container',
  props: {
    col: {
      type: Number,
      default: 7
    },
    colClass: String,
    rowClass: String
  },
  computed: {
    xl () { return this.maxSize(this.col) },
    lg () { return this.maxSize(this.col + 1) },
    md () { return this.maxSize(this.col + 3) },
    sm () { return this.maxSize(this.col + 5) },
    xs () { return this.maxSize(this.col + 7) }
  },
  methods: {
    maxSize (col) { return col <= 12 ? col : 12 }
  }
}
</script>