import store from '../store'

export default {
    baseUrl: process.env.VUE_APP_API_URL,
    getContrats() {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/client/contrats'

        return window.axios.get(url, config)
    },
    getFactures() {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/documents/factures'

        return window.axios.get(url, config)
    },
    getAttestations() {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/documents/attestations'

        return window.axios.get(url, config)
    },
    getFacture(id) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json',
            },
            responseType: 'blob'
        }
        let url = this.baseUrl + `api-m/einstein/documents/facture/${id}`

        return window.axios.get(url, config)
    },
    getAttestation(id) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json',
            },
            responseType: 'blob'
        }
        let url = this.baseUrl + `api-m/einstein/documents/attestation/${id}`

        return window.axios.get(url, config)
    },
    getEtabs() {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json',
            }
        }
        let url = this.baseUrl + `api-m/einstein/client/etabs`

        return window.axios.get(url, config)
    },
    updateRecipient(params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + `api-m/einstein/client/recipient/${params.idrecipient}`

        return window.axios.post(url, params, config)
    }
}