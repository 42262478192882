<template>
  <div class="page app-page">
    <center-container>
      <div v-if="!noHeader"
           class="page-header"
           :class="{'page-header--back': back}">
        <div v-if="back"
             class="page-header__back">
          <b-link @click="back">< Retour</b-link>
        </div>
        <p class="page-header__title display-4 text-center w-100 family-bold">{{ title }}</p>
        <div class="page-header__bell">
<!--          <b-icon-bell-fill class="display-2"></b-icon-bell-fill>-->
        </div>
      </div>
    </center-container>
    <slot></slot>
  </div>
</template>

<script>
import { BIconBellFill } from 'bootstrap-vue'
import CenterContainer from '@/components/layout/CenterContainer'

export default {
  name: 'app-page',
  components: { CenterContainer, BIconBellFill },
  props: {
    title: String,
    back: Function,
    noHeader: Boolean
  }
}
</script>